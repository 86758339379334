body{
    background: $background-color;
    font-family: 'Museo Sans', sans-serif;
    font-size: 13px;
    box-sizing: border-box;
    font-weight: 300;
}


.kassa{
    padding: 10px;
    height: var(--tg-viewport-stable-height);
    display: flex;
    flex-direction: column;
    position: relative;
    background: $background-color;


    .header{
        width: 100%;
        height: 100%;
        margin-bottom: 10px;

        .search{
            display: flex;

            .logo{
                //width: 40px;
                width: 0;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                img{
                    width: 80%;
                    height: auto;
                }

            }

            .back{
                width: 40px;
                height: 40px;

                button{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid $border-color;
                    border-radius: 5px;
                    background: transparent;
                    transition: $time-animation;
                    cursor: pointer;

                    svg{
                        width: auto;
                        height: 16px;

                        path{
                            transition: $time-animation;
                            fill: $border-color;
                        }
                    }

                    &:hover{
                        border: 1px solid $border-active-color;

                        svg{
                            path{
                                fill: $border-active-color;
                            }
                        }
                    }
                }
            }

            .logo, .back{
                display: flex;
                align-items: center;
                justify-content: center;
                transition: $time-animation;
                margin-right: 10px;
                overflow: hidden;

                &.hide{
                    width: 0;
                    margin-right: 0;
                }
            }

            .logo{
                margin-right: 0;
            }

            .search{
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: start;

                input{
                    background-color: #ecf0f6;
                    width: 100%;
                    height: 100%;
                    outline: none;
                    border: 0;
                    transition: $time-animation;
                    border-radius: 5px;
                    padding: 7px 8px;
                    box-sizing: border-box;
                    font-size: 13px;
                    color: #000;
                    position: relative;

                    &::placeholder{
                        color: $placeholder;
                    }

                    &.hide{
                        width: 0;
                        padding: 0;
                        border: 0;
                    }

                }

                p{
                    color: $text-main-color;
                    opacity: 0;
                    width: 0;
                    transition: $time-animation;

                    &.show{
                        opacity: 1;
                        width: 100%;
                    }
                }
            }
        }

        .currencies{
            display: flex;
            margin-top: 10px;
            justify-content: start;
            height: auto;
            transition: $time-animation;
            overflow: hidden;
            opacity: 1;

            span{
                padding: 5px 10px;
                border: 1px solid #d8dade;
                border-radius: 5px;
                margin-right: 5px;
                cursor: pointer;
                color: $text-main-color;
                transition: $time-animation;

                &:last-child{
                    margin-right: 0;
                }

                &.active{
                    border: 1px solid $border-active-color;
                }
            }

            &.hide{
                height: 0;
                margin-top: 0;
                opacity: 0;
            }
        }
    }

    .directions{
        display: block;
        visibility: visible;
        animation: 200ms show-direction ease-out both;

        @keyframes show-direction {
            0%{
                opacity: 0;
                visibility: hidden;
            }
            50%{
                opacity: 0;
                visibility: visible;
            }
            100%{
                opacity: 1;
                visibility: visible;
            }
        }

        &.hide{
            animation: 200ms hide-direction ease-out both;

            @keyframes hide-direction {
                0%{
                    opacity: 1;
                    visibility: visible;
                }
                50%{
                    opacity: 0;
                    visibility: hidden;
                }
                100%{
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                }
            }
        }

        .direction{
            display: flex;
            align-items: center;
            width: 100%;
            height: 40px;
            //border: 1px solid $border-color;
            border: 0px;
            border-radius: 5px;
            padding: 5px;
            box-sizing: border-box;
            color: $text-main-color;
            cursor: pointer;
            margin-bottom: 5px;
            transition: $time-animation;

            &:last-child{
                margin-bottom: 0;
            }

            .icon{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                margin-right: 10px;
                margin-left: 5px;
                border-radius: 50%;
                background-color: $logo-bg-color;

                img{
                    height: auto;
                    width: 60%;
                }

                span{
                    width: 100%;
                    height: 100%;
                    font-size: 0
                }
            }

            &:hover{
                //border: 1px solid $border-active-color;
                border: 0px;
            }

        }
    }

    &.login{
        h1{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 10px;
            color: $text-main-color;
        }

        .input-block{
            width: 100%;

            span {
                color: $text-main-color;
            }

            .input-with-image{
              display: flex;
              width: 100%;
              height: 40px;

              .icon{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: $border-color;
                  border-radius: 5px 0px 0px 5px;
                  width: 40px;
                  height: 40px;

                  img{
                      width: 25px;
                      height: auto;
                  }

                  svg{
                      width: 25px;
                      height: auto;
                      color: $text-main-color;
                  }
              }

              input{
                  width: 100%;
                  height: 40px;
                  border-width: 1px 1px 1px 0px;
                  border-style: solid;
                  border-color: $border-color;
                  border-radius: 0px 5px 5px 0px;
                  background: transparent;
                  padding: 12px 8px;
                  outline: none;
                  color: $text-main-color;

                  &::placeholder{
                      color: $placeholder;
                  }
              }
            }

            .input-with-text{
              display: flex;
              width: 100%;
              height: 40px;

                  input{
                      width: 100%;
                      height: 40px;
                      border-width: 1px 1px 1px 1px;
                      border-style: solid;
                      border-color: $border-color;
                      border-radius: 5px 5px 5px 5px;
                      background: transparent;
                      padding: 12px 8px;
                      outline: none;
                      color: $text-main-color;

                      &::placeholder{
                          color: $placeholder;
                      }
                  }
              }

              .bit{
                  margin-bottom: 7px;

                  &:last-child{
                      margin-bottom: 0;
                  }
              }
        }

        .options{
            margin-top: 2px;
            display: flex;
            justify-content: end;
            font-size: 12px;
            width: 100%;
            color: $text-main-color;

            a{
                color: $text-main-color;
            }

            span{
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        .next{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 10px;

            .scan-error{
                margin-top: 10px;
                background: #6a9bcc !important;
            }

            .next-button{
                color: #ffffff;
                width: 100%;
                height: 40px;
                background: $border-active-color;
                border: 0;
                border-radius: 5px;
                font-weight: 500;
                font-size: 13px;
                cursor: pointer;
                transition: $time-animation;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &:disabled{
                    opacity: 0.6;
                    cursor: no-drop;
                }

                span{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    transition: $time-animation;
                    overflow: hidden;
                }

                .loader-button{
                    height: 0;
                    transition: $time-animation;
                    overflow: hidden;
                }

                &.loading{

                    span{
                        height: 0;
                    }

                    .loader-button {
                        display: inline-block;
                        position: relative;
                        top: 0;
                        left: 0;
                        width: 38px;
                        height: 38px;

                        div {
                            display: inline-block;
                            position: absolute;
                            left: 0px;
                            width: 10px;
                            background: $loader-button;
                            animation: loader-button 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

                            &:nth-child(1) {
                                left: 0px;
                                animation-delay: -0.24s;
                            }
                            &:nth-child(2) {
                                left: 14px;
                                animation-delay: -0.12s;
                            }
                            &:nth-child(3) {
                                left: 28px;
                                animation-delay: 0;
                            }
                          }
                      }

                      @keyframes loader-button {
                        0% {
                          top: 5px;
                          height: 30px;
                        }
                        50%, 100% {
                          top: 10px;
                          height: 20px;
                        }
                      }
                }
            }
        }

        .notify{
            width: 100%;

            .error, .success{
                margin-top: 10px;
                color: #fff;
                font-weight: 350;
                font-size: 16px;
                border-radius: 5px;
                padding: 7px 10px;
                animation: $time-animation notifylogin ease-out both;
                opacity: 0;
                transform: scale(100%);
                transition: $time-animation;

                &.hide{
                    padding: 0;
                    transform: scale(0%);
                }
            }

            @keyframes notifylogin {
                0%{
                    opacity: 0;
                }
                100%{
                    opacity: 1;
                }
            }

            .error{
                background: #E42E2F;
            }

            .success{
                background: #4BB543;
            }
        }
    }

    &.lk{
        color: $text-main-color;

        .header{
            display: flex;
            align-items: center;

            .hello{
                width: 100%;
                font-weight: 500;
                font-size: 14px;
                padding: 5px 5px 5px 10px;
                box-sizing: border-box;
                border-radius: 20px 0 0 0;
            }

            .exit{
                color: $text-main-color;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                border: 0;
                background: transparent;
                cursor: pointer;
                padding: 0;

                svg{
                    width: 15px;
                    height: auto;
                    overflow: visible;

                    path{
                        transition: $time-animation;

                        &:nth-child(2){
                            transform: translateX(0px);
                        }
                    }
                }

                &:hover{
                    svg{
                        path{
                            &:nth-child(2){
                                transform: translateX(3px);
                            }
                        }
                    }
                }
            }
        }

        .applications{
            display: flex;
            flex-direction: column;

            .application {
                display: flex;
                flex-direction: column;
                align-items: center;
                box-sizing: border-box;
                padding: 5px 10px 10px 10px;
                border: 1px solid $border-color;
                border-radius: 5px;

                .number {
                    display: flex;
                    justify-content: space-between;
                    font-weight: 500;
                    font-size: 12px;
                    color: $border-color;
                    margin-bottom: 24px;
                    width: 100%;
                }

                .bridge{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 17px;

                    .from, .to{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 45%;

                        .logo{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40px;
                            height: 40px;

                            img{
                                height: 25px;
                                width: auto;
                            }
                        }

                        .cost{
                            color: $text-main-color;
                            font-weight: 500;
                            font-size: 12px;
                            text-align: center;
                        }

                        .name{
                            color: $text-main-color;
                            font-weight: 500;
                            font-size: 10px;
                        }
                    }

                    .arrow{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 10%;
                        color: $text-main-color;

                        svg{
                            color: $text-main-color;
                            height: 33px;
                            width: auto;
                        }
                    }
                }

                .responce{
                    text-align: center;
                    font-weight: 500;
                    font-size: 12px;

                    p{
                        &.error{
                            color: #E30000;
                        }

                        &.success{
                            color: #05E300;
                        }

                        &.waiting{
                            color: #ffdc00;
                        }

                        a{
                            color: $text-main-color;
                            text-decoration: underline;
                            transition: $time-animation;

                            &:hover{
                                opacity: 0.8;
                            }
                        }
                    }
                }

                margin-bottom: 10px;

                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }

}

.header{
    width: 100%;
    height: 100%;
    margin-bottom: 10px;

    &.ex{
        margin-bottom: 0;
    }

    .search{
        display: flex;

        .back{
            width: 40px;
            height: 40px;

            button{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $border-color;
                border-radius: 5px;
                background: transparent;
                transition: $time-animation;
                cursor: pointer;

                svg{
                    width: auto;
                    height: 16px;

                    path{
                        transition: $time-animation;
                        fill: $border-color;
                    }
                }

                &:hover{
                    border: 1px solid $border-active-color;

                    svg{
                        path{
                            fill: $border-active-color;
                        }
                    }
                }
            }
        }

        .back{
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $time-animation;
            margin-right: 10px;
            overflow: hidden;

            &.hide{
                width: 0;
                margin-right: 0;
            }
        }

        .search{
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: start;

            p{
                color: $text-main-color;
                opacity: 0;
                width: 0;
                transition: $time-animation;

                &.show{
                    opacity: 1;
                    width: 100%;

                    &.ex{
                        font-weight: 500;
                        font-size: 14px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

}

.ex-block{
        font-size: 13px;
        .info-block{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px;
            box-sizing: border-box;
            margin: 5px 0 10px 0;
            border: 0.7px solid $border-color;
            border-radius: 5px;
            color: $text-main-color;

            h1{
                font-weight: 500;
                font-size: 13px;
                margin: 5px 0 10px 0;
            }

            .ex-rate{
                border-top: 0.7px solid $border-color;
                width: 100%;

                .direction{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 40px;

                    .text{
                        display: flex;
                        align-items: center;
                        margin: 0 0 0 7.5px ;

                        .icon{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 30px;
                            height: 30px;
                            margin-right: 17.5px;
                            border-radius: 50%;
                            background-color: $logo-bg-color;

                            img{
                                width: 60%;
                                height: auto;
                            }
                        }
                    }

                    .cost{
                        text-transform: uppercase;
                        color: grey;
                        margin-right: 5px;
                    }
                }
            }
        }

        .input-block{
          width: 100%;

          .input-with-image{
            display: flex;
            width: 100%;
            height: 40px;

            .icon{
                display: flex;
                justify-content: center;
                align-items: center;
                background: $form-bg-color;
                border-radius: 5px 0px 0px 5px;
                width: 40px;
                height: 40px;

                img{
                    width: 23px;
                    height: auto;
                }

                svg{
                    width: 23px;
                    height: auto;
                    color: $bg-input-icon;

                    path{
                        fill: $bg-input-icon;
                    }
                }
            }

            input{
                width: 100%;
                height: 40px;
                border-width: 1px 1px 1px 0px;
                border-style: solid;
                border-color: $border-color;
                border-radius: 0px 5px 5px 0px;
                background: transparent;
                padding: 12px 8px;
                outline: none;
                color: $text-main-color;

                &::placeholder{
                    color: $placeholder;
                }
            }
          }

          .input-with-text{
            display: flex;
            width: 100%;
            height: 40px;

                input{
                    width: 100%;
                    height: 40px;
                    border-width: 1px 1px 1px 1px;
                    border-style: solid;
                    border-color: $border-color;
                    border-radius: 5px 5px 5px 5px;
                    background: transparent;
                    padding: 12px 8px;
                    outline: none;
                    color: $text-main-color;

                    &::placeholder{
                        color: $placeholder;
                    }
                }
            }

            .bit{
                margin-bottom: 7px;

                &:last-child{
                    margin-bottom: 0;
                }
            }
        }

        .check-form{
            width: 100%;
            margin-top: 25px;
            margin-bottom: 70px;

            .check-c{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid $border-color;
                border-radius: 5px;
                padding: 5px 7px;
                margin-bottom: 7px;

                &:last-child{
                    margin-bottom: 0;
                }

                .text{
                    font-weight: 500;
                    font-size: 12px;
                    color: $text-main-color;
                }

                .delete{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: transparent;
                    border: 0;
                    cursor: pointer;
                    height: 100%;

                    svg{
                        width: 12px;
                        height: 17px;
                    }
                }
            }
        }

        .notification {
            width: 100%;
            height: 100%;
            background: none;
            border-radius: 5px;
            border-width: 1px 1px 1px 1px;
            border-style: solid;
            border-color: $border-active-color;
            color: $text-main-color;
            padding: 5px 8px;
            margin-top: 10px;
        }
        .error{
            width: 100%;
            height: 100%;
            background: #CC0000;
            border-radius: 5px;
            color: white;
            padding: 5px 8px;
            margin-top: 10px;
            animation: $time-animation show-error ease-out both;

            &.hide{
                display: none;
            }

            @keyframes show-error {
                0%{
                    opacity: 0;
                }
                100%{
                    opacity: 1;
                }
            }
        }

        .next{
            position: relative;
            bottom: 0;
            left: 0;
            width: 100%;
            margin-top: 10px;

            .next-button{
                width: 100%;
                height: 40px;
                background: $border-active-color;
                border: 0;
                border-radius: 5px;
                font-weight: 500;
                font-size: 13px;
                cursor: pointer;
                transition: $time-animation;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &:disabled{
                    opacity: 0.6;
                    cursor: no-drop;
                }

                span{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    transition: $time-animation;
                    overflow: hidden;
                }

                .loader-button{
                    height: 0;
                    transition: $time-animation;
                    overflow: hidden;
                }

                &.loading{

                    span{
                        height: 0;
                    }

                    .loader-button {
                        display: inline-block;
                        position: relative;
                        top: 0;
                        left: 0;
                        width: 38px;
                        height: 38px;

                        div {
                            display: inline-block;
                            position: absolute;
                            left: 0px;
                            width: 10px;
                            background: $loader-button;
                            animation: loader-button 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

                            &:nth-child(1) {
                                left: 0px;
                                animation-delay: -0.24s;
                            }
                            &:nth-child(2) {
                                left: 14px;
                                animation-delay: -0.12s;
                            }
                            &:nth-child(3) {
                                left: 28px;
                                animation-delay: 0;
                            }
                          }
                      }

                      @keyframes loader-button {
                        0% {
                          top: 5px;
                          height: 30px;
                        }
                        50%, 100% {
                          top: 10px;
                          height: 20px;
                        }
                      }
                }
            }

            &.ex{
                position: fixed;
                padding: 10px;
            }
        }
}