// :root {
//   --tg-color-scheme: dark;
//   --tg-theme-bg-color: #17212b;
//   --tg-theme-button-color: #5288c1;
//   --tg-theme-button-text-color: #ffffff;
//   --tg-theme-hint-color: #708499;
//   --tg-theme-link-color: #6ab3f3;
//   --tg-theme-secondary-bg-color: #232e3c;
//   --tg-theme-text-color: #f5f5f5;
//   --tg-viewport-height: 100vh;
//   --tg-viewport-stable-height: 100vh;
// }

$background-color: var(--tg-theme-bg-color);
$border-color: var(--tg-theme-hint-color);
$placeholder: var(--tg-theme-hint-color);;
$border-active-color: #005587;
$text-main-color: var(--tg-theme-text-color);
$time-animation: 200ms;
$preloader-background: var(--tg-theme-bg-color);

$loader-button: white;

$logo-bg-color: #ecf0f6;
$form-bg-color: #ecf0f6;
$bg-input-icon: #000;

.react-loading-skeleton{
  --base-color: var(--tg-theme-secondary-bg-color) !important;
  --highlight-color: var(--tg-theme-hint-color) !important;
  --animation-duration: 0.8s !important;
}