@import 'config';
@import './fonts/stylesheet.css';
@import 'reset';
@import 'mainpage';

.preloader{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: $preloader-background;
    opacity: 1;
    visibility: visible;
    transition: $time-animation;

    &.hide{
        opacity: 0;
        visibility: hidden;
    }

    .loading {
        display: inline-block;
        position: relative;
        top: 0;
        left: 0;
        width: 38px;
        height: 38px;

        div {
            display: inline-block;
            position: absolute;
            left: 0px;
            width: 10px;
            background: $text-main-color;
            animation: preloader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

            &:nth-child(1) {
                left: 0px;
                animation-delay: -0.24s;
            }
            &:nth-child(2) {
                left: 14px;
                animation-delay: -0.12s;
            }
            &:nth-child(3) {
                left: 28px;
                animation-delay: 0;
            }
        }
    }
    
    @keyframes preloader {
        0% {
            top: 5px;
            height: 30px;
        }
        50%, 100% {
            top: 10px;
            height: 20px;
        }
    }
}

.mainerror{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 995;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.9);
    opacity: 1;
    visibility: visible;
    transition: $time-animation;

    h1{
        font-size: 20px;
        margin-bottom: 20px;
        color: red;
    }

    p{
        font-size: 17px;
        width: 70%;
        word-break: break-word;
        color: white;
    }

    &.hide{
        opacity: 0;
        visibility: hidden;
    }
}